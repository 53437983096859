import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import clientConfig from "../../../client-config"

const Figure = ({ node, maxWidth = 1920, objectFit = "cover" }) => {
  if (!node || !node.asset || !node.asset._ref) {
    return null
  }
  const fluidProps = getFluidGatsbyImage(
    node.asset._ref,
    { maxWidth: maxWidth },
    clientConfig.sanity
  )

  return (
    <Img
      fluid={fluidProps}
      alt={node.alt}
      imgStyle={{ objectFit: objectFit }}
    />
  )
}

export default Figure
